// noinspection HtmlUnknownTarget

import SEO from "$components/seo"
import React from "react"

const Metadata = () => {
  return (
    <SEO
      title="Alde & Maria Wedding"
      description="22 January 2022 || Kapel Desa Putra, Jagakarsa."
      ogImage="/og-image.jpg"
    >
      <link
        rel="preload"
        href="/static/eb-garamond-latin-700-normal-69251bb2bb22dd06a4bcde97328fb910.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/static/eb-garamond-latin-400-normal-d1869fac8f61f54f5a472ced98bc4219.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </SEO>
  )
}

export default Metadata
