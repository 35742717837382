import React, { FC } from "react"

const Footer: FC<{
  daysLeft: number
  time: string
  mapLink: string
  buttonClassname?: string
  livestreamLink?: string
}> = ({
  daysLeft,
  time,
  livestreamLink,
  mapLink,
  buttonClassname = "bg-romeo-text",
}) => (
  <div className="fixed bottom-0 inset-x-0 bg-romeo-background z-30 bg-opacity-70 backdrop-blur-lg backdrop-filter md:hidden bg-romeo-background px-4 py-2 flex items-center">
    <div className={"text-sm font-medium mr-auto"}>
      {daysLeft < 0 && <p>We've Married!!</p>}
      {daysLeft === 0 && <p>Today at {time}!</p>}
      {daysLeft > 0 && <p>{daysLeft} days from now.</p>}
    </div>
    {livestreamLink && (
      <a
        href={livestreamLink}
        className="px-4 py-3 rounded-lg inline-block font-bold flex-shrink-0 flex items-center text-sm"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        Livestream
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 ml-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          />
        </svg>
      </a>
    )}
    <a
      href={mapLink}
      className={`px-4 py-3 rounded-lg text-white inline-block text-sm font-medium flex-shrink-0 ${buttonClassname}`}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      Peta
    </a>
  </div>
)

export default Footer
