import React, { FC } from "react"
import { Dayjs } from "dayjs"

const DesktopFrame: FC<{ dueDate: Dayjs }> = ({ dueDate }) => {
  return (
    <>
      <div className="fixed left-0 top-0 flex-col items-center p-16 font-romeo hidden xl:flex z-20">
        <p>A</p>
        <p>/</p>
        <p>M</p>
      </div>

      <div className="fixed right-0 top-0 flex-col items-center p-16 font-romeo hidden xl:flex z-20">
        <p>{dueDate.format("MMM")}</p>
        <p>/</p>
        <p>{dueDate.date()}</p>
      </div>

      <div className="fixed text-md origin-top-left transform -rotate-90 left-0 bottom-0 flex-col items-center m-16 font-romeo hidden xl:flex z-20">
        Jagakarsa, Jakarta Selatan
      </div>
    </>
  )
}

export default DesktopFrame
